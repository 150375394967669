<template>
    <!-- 多媒体消息 》Thunder 5G SMS签名管理 -->
    <div id="app_sign_id">
        <!-- 头 -->
        <el-row class="head_top">
            <div class="head_top_back cursor"><i class="el-icon-arrow-left" @click="goBack()"></i></div>
            <div class="head_top_right">
                <el-button type="primary" @click="add()" size="mini">
                    新建签名
                </el-button>
                <el-button type="primary" @click="import_sign()" size="mini">
                    导入签名
                </el-button>
                <el-button type="primary" @click="export_sign()" size="mini">
                    导出签名
                </el-button>
            </div>
            <div class="head_top_title">多媒体消息签名管理</div>
        </el-row>

         <!-- 体 -->
        <el-row style="margin:10px 0px 0px;padding:0px 15px;background-color: white;">
            <el-row style="overflow: auto;" :style="{height: ((content_heigth)+'px')}">
                <el-table  :data="tableData" :show-header="true" border style="width: 100%" size="mini">
                    <el-table-column prop="signword_id" label="签名编号" min-width="80" align="center"> </el-table-column>
                    <el-table-column prop="signword" label="签名" min-width="80" align="center"> </el-table-column>
                    <el-table-column prop="state" label="状态" min-width="80" align="center"></el-table-column>
                    <el-table-column prop="user_company_name" label="签名所属客户公司" min-width="80" align="center"> </el-table-column>
                    <el-table-column label="操作" min-width="80" align="center">
                        <template slot-scope="scope">
                            <el-button type="text" size="mini" @click="detail(scope.row.id)">详情</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-row>
        </el-row>

        <!-- 新增多媒体签名 -->
        <MediaSignAdd :prop_change_i="prop_sign_channel_i" :prop_channel_id="prop_sign_channel_id"
        @add_sign="add_sign"></MediaSignAdd>

        <!-- 导入多媒体签名 -->
        <MediaSignImport :prop_change_i="prop_import_sign_i" :prop_channel_id="prop_import_sign_id"
        @add_sign="add_sign"></MediaSignImport>

        <!-- 导出多媒体签名 -->
        <MediaSignExport :prop_change_i="prop_export_sign_i" :prop_channel_id="prop_export_sign_id"
        @add_sign="add_sign"></MediaSignExport>

        <!-- 多媒体签名详情 -->
        <MediaSignDetail :prop_change_i="prop_sign_detail_i" :prop_sign_id="prop_sign_detail_id"></MediaSignDetail>

    </div>
</template>
<script>
import API from '../../api/api';
import MediaSignAdd from './media_sign_add.vue';//组件 多媒体消息新建签名
import MediaSignImport from './media_sign_import.vue';//组件 多媒体消息导入签名
import MediaSignExport from './media_sign_export.vue';//组件 多媒体消息导出签名
import MediaSignDetail from './media_sign_detail.vue';//组件 多媒体签名详情

export default {

    components: {
        MediaSignDetail,
        MediaSignImport,
        MediaSignExport,
        MediaSignAdd
    },
    
    data(){
        return{
            content_heigth: 500,//内容的高度
            content_width: 500,//内容的宽度

            channe_id : 0,//通道id

            tableData: [],//表格数据

            //新建多媒体签名
            prop_sign_channel_i: 0,//新建多媒体签名  组件 弹出框是否可见
            prop_sign_channel_id: "",//新建多媒体签名  组件  通道id

            //导入多媒体签名
            prop_import_sign_i: 0,//导入多媒体签名  组件 弹出框是否可见
            prop_import_sign_id: "",//导入多媒体签名 组件  通道id

            //导出多媒体签名
            prop_export_sign_i: 0,//导出多媒体签名  组件 弹出框是否可见
            prop_export_sign_id: "",//导出多媒体签名 组件  通道id

            //多媒体签名详情
            prop_sign_detail_i: 0,//多媒体签名详情  组件 弹出框是否可见
            prop_sign_detail_id: "",//多媒体签名详情  组件  签名id

        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
    },
    //activated和deactivated配合keep-alive标签使用!
    activated() {
        //重置所有数据
	    Object.assign(this.$data, this.$options.data.call(this));
	    //初始化设置
        this.initSetUp();
        
        //查询
        this.getData();
    },
    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            // console.log("document.body.clientWidth:"+document.body.clientWidth+" "+document.body.clientHeight);
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 80);
            //console.log("content_heigth:"+this.content_heigth+" clientHeight:"+clientHeight);

            this.channe_id = this.$route.params.id;
        },
        //增加签名
        add(){
            this.prop_sign_channel_id = this.channe_id;
            this.prop_sign_channel_i ++;//弹出框是否可见
        },
        //导入签名
        import_sign(){
            this.prop_import_sign_id = this.channe_id;
            this.prop_import_sign_i ++;//弹出框是否可见
        },
        //导出签名
        export_sign(){
            this.prop_export_sign_id = this.channe_id;
            this.prop_export_sign_i ++;
        },
        //签名详情
        detail(sign_id){
            this.prop_sign_detail_id = sign_id;
            this.prop_sign_detail_i ++;//弹出框是否可见
        },
        //添加签名后回调
        add_sign(){
            this.getData();
        },
        //查询
        getData(){
            //请求接口
            API.MediaSignServlet({
                param: "mediaSignList",
                channel_id: this.channe_id,
            }).then((res) => {
                if (res.recode === 0) {
                    this.tableData = res.list;

                    this.$nextTick(function(){
                        //获取分页的高度
                        var pagination = document.getElementById('pagination_id');
                        if(!API.isEmtry(pagination)){
                            this.pagination_height = pagination.clientHeight;
                        }
                    });
                } 
            });
        },
        //返回
        goBack() {
            API.router_to("/media");
        }
    }
};
</script>

<style scoped>
/*form表单样式调整*/
#app_check_rate_list>>>.el-form-item {
  margin-bottom: 0px;
}
</style>