<template>
    <!-- 多媒体消息 》Thunder 5G SMS签名导出 -->
    <div id="app_sign_export_id">
        <!-- 多媒体消息导出签名 -->
        <el-dialog class="cur_dialog" title="" center :visible.sync="dialog_visible" fullscreen destroy-on-close :show-close="true" :modal-append-to-body="false">
            <!-- 头 -->
            <el-row class="dialog_top">
                <span>多媒体消息导出签名</span>
                <i class="el-icon-close dialog_top_close_button" @click="back()" style="font-size:24px"></i>
            </el-row>
             <div style="margin: 10px 20px">
                <el-button type='primary' @click="toggleSelection()" size="mini">导出签名</el-button>
            </div>

            <!-- 体 -->
            <el-row style="margin:10px 0px 0px;padding:0px 15px;background-color: white;">
                <el-row style="overflow: auto;" :style="{height: ((content_heigth)+'px')}">
                    <el-table :data="tableData" :show-header="true" border style="width: 100%" size="mini" ref="multipleTable"  @selection-change="handleSelectionChange">
                        <el-table-column type="selection" width="55"></el-table-column>
                        <el-table-column prop="signword_id" label="签名编号" min-width="80" align="center"> </el-table-column>
                        <el-table-column prop="signword" label="签名" min-width="80" align="center"> </el-table-column>
                        <el-table-column prop="state" label="状态" min-width="80" align="center"></el-table-column>
                        <el-table-column prop="user_company_name" label="签名所属客户公司" min-width="80" align="center"> </el-table-column>
                    </el-table>
                </el-row>
            </el-row>
        </el-dialog>
    </div>
</template>
<script>
import API from '../../api/api';
import {
    getItem
} from '../../api/tool.js';

export default {

    props:{
        prop_change_i: 0,
        prop_channel_id: '',//通道id
    },
    watch:{//监听props
        prop_change_i(val){
            if(val == 0){
                this.dialog_visible = false;//弹出框是否可见 
            }else{
                this.dialog_visible = true;//弹出框是否可见 
                setTimeout(() => {
                    this.getData();
                }, 1);
            }
        }
    },
    
    data(){
        return{
            dialog_visible: false,//弹出框是否可见  false:不可见

            content_heigth: 500,//内容的高度
            content_width: 500,//内容的宽度

            channe_id : 0,//通道id

            tableData: [],//表格数据
            multipleSelection: [],//选中行数据
            select_ids: [],//选中行id集合

        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
    },
    //activated和deactivated配合keep-alive标签使用!
    activated() {
        //重置所有数据
	    Object.assign(this.$data, this.$options.data.call(this));
	    //初始化设置
        this.initSetUp();

        //查询
        this.getData();
    },
    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            // console.log("document.body.clientWidth:"+document.body.clientWidth+" "+document.body.clientHeight);
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 80);
            //console.log("content_heigth:"+this.content_heigth+" clientHeight:"+clientHeight);

            this.channe_id = this.$route.params.id;
        },
        handleSelectionChange(val) {
            console.log("val="+val);
            this.select_ids = [];
            val.forEach(row => {
                console.log("id="+row.id);
                this.select_ids.push(row.id);
            });
            this.multipleSelection = val;
            console.log("this.select_ids="+this.select_ids);
        },
        //查询
        getData(){
            //请求接口
            API.MediaSignServlet({
                param: "mediaSignList",
                channel_id: this.channe_id,
                state: "100",//状态：0：审核中 1：上线 2：下线 100：审核成功 000：审核失败 -1：删除
            }).then((res) => {
                if (res.recode === 0) {
                    this.tableData = res.list;

                    this.$nextTick(function(){
                        //获取分页的高度
                        var pagination = document.getElementById('pagination_id');
                        if(!API.isEmtry(pagination)){
                            this.pagination_height = pagination.clientHeight;
                        }
                    });
                } 
            });
        },
        //导出签名
        toggleSelection() {
            // 请求接口
            API.MediaSignServlet({
                param: "exportMediaSignThunder",
                channel_id: this.channe_id,
                ids: this.select_ids.join(','),
            }).then((res) => {
                if (res.recode === 0) {
                    window.open(res.downUrl);
                    this.dialog_visible = false;//弹出框是否可见   false:不可见
                }
            });
        },
        //关闭
        back(){
            this.dialog_visible = false;//弹出框是否可见   false:不可见
        },
    }
};
</script>

<style scoped>
/* 增加或编辑弹框中内容距离标题的距离 */
.cur_dialog >>>.el-dialog__body {
    padding: 0px 0px;
}
.cur_dialog >>>.el-dialog__header {
    padding: 0px 0px;
}
/*form表单 间隔*/
.el-form-item {
  margin-bottom: 10px;
}
</style>